import { Button } from '@mui/material';
import { useTranslation } from 'next-i18next';

export default function DialogActionCloseButton({ onClick }) {

    const { t } = useTranslation('common')

    return (
        <Button
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            variant="text"
            onClick={onClick}>
            {t('action.done')}
        </Button>
    );
}