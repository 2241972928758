import { useState } from 'react';
import { createFormRecord } from 'utils/formsApi';
import alerts from 'modules/yoio/state/alerts';
import { isHavingValue } from 'utils/objectUtils';
import { removeArrayItem } from 'utils/arrayUtils';
import { getWindowLocationHrefSafe } from 'utils/windowUtils';
import { useRouter } from 'next/router';
import { FormDef } from './FormTypes';

/**
 * submit generic form records
 */

export interface UseFormSubmitProps {
    form: FormDef 
    onSuccess?: () => Promise<any>
    onError?: (data?: any)=>void
}

export const useFormSubmit = ({form, onSuccess, onError}:UseFormSubmitProps) => {

    const router = useRouter()

    const [createdAtMs] = useState(Date.now())

    const [progress, setProgress] = useState<boolean>()

    const getSecondsSinceCreate = () => {
        return Math.round((Date.now() - createdAtMs) / 1000);
    }

    const submitForm = async (data, dontContinueToAfterSubmitUrl) => {
        if (!form.formId) {
            console.error('unexpected error - no form id');
            return;
        }

        setProgress(true)

        const errors = [];

        if (isJustOneDataFieldAndEmpty(data)) {
            errors.push('Field cannot be empty')
        }

        if (errors.length > 0) {
            alerts.error(errors[0]);
            if (onError) {
                onError(data)
            }
            return;
        }

        if (form.isProtectionEnabled && getSecondsSinceCreate() <= 3) {
            //Spam Protection
            alerts.error('Unexpected error. Please try again.');
            if (onError) {
                onError(data)
            }
            return;
        }

        data.metaLocation = getWindowLocationHrefSafe() //its just about the "ref" query param

        const onAfterSuccess = () => {
            if (form.afterSubmitUrl && dontContinueToAfterSubmitUrl !== true) {
                router.push(form.afterSubmitUrl)
            } else {
                setProgress(false)
            }
        }

        return createFormRecord(form.formId, data).then(()=>{
            if (!form.afterSubmitUrl) {
                alerts.success('Submitted.');
            }
            if (onSuccess) {
                onSuccess().then(()=>{
                    onAfterSuccess()
                })
            } else {
                onAfterSuccess()
            }
        }).catch((e) => {
            alerts.error('Unexpected error. Please try again.');
            if (onError) {
                onError(data)
            }
            setProgress(false)
        });
    };

    return { submitForm, progress, forwarding: progress && isHavingValue(form.afterSubmitUrl) }


}

const isJustOneDataFieldAndEmpty = (data) => {
    const realDataKeys = Object.keys(data);
    removeArrayItem(realDataKeys, 'submit')
    removeArrayItem(realDataKeys, 'pageOrigin')

    let isError = false;

    //validation
    if (realDataKeys.length === 1) {
        //just one field - check if it is empty
        realDataKeys.forEach((v)=>{
            const value = data[v];
            if (!isHavingValue(value) || value.length === 0) {
                isError = true;
            }
        })
    }

    return isError;
}