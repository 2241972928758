import dynamic from 'next/dynamic';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme:Theme)=>({
    root: {
        fontFamily: theme.typography.fontFamily,
        '& textarea': {
            fontFamily: theme.typography.fontFamily,
        },
        '& button': {
            backgroundColor: theme.palette.primary.main + ' !important',
            border: 'none !important',
            //@ts-ignore contrastText?
            color: theme.palette.text.contrastText,
            fontWeight: '500',
            //paddingLeft: theme.spacing(6),
            //paddingRight: theme.spacing(6),
            height: '40px !important',
            fontFamily: theme.typography.fontFamily + ' !important',
            '&:active,&:focus,&:hover': {
                border: 'none !important',
                boxShadow: 'none !important'
            }
        },
        '& button:disabled': {
            backgroundColor: theme.palette.action.disabledBackground + ' !important',
            border: 'none !important',
            color: theme.palette.action.disabled
        }

    },
    rootSubmitButtonFullWidth: {
        '& button[type=submit]': {
            width: '100%'
        }
    },
    rootSubmitButtonIncentiveStyle: {
        '& button[type=submit]': {
            borderRadius: '65px 65px 65px 65px'
        }
    },
    rootInputIncentiveStyle: {
        '& input[type=text], & input[type=email]': {
            borderRadius: '65px 65px 65px 65px',
            border: 'none !important',
            boxShadow: 'none !important',
            '&:active,&:focus,&:hover': {
                border: 'none !important',
                boxShadow: 'none !important'
            }
        },
    }
}));

const FormInternal = dynamic(
    () => import('./FormInternal'),
    { ssr:
 false }
  )

export const FormSimple = ({ form, initialData, logLevel }:{form: any, initialData?: any, logLevel?: string }) => {

    const classes = useStyles();

    let classNames = [classes.root];
    if (form.styling?.submitButtonFullWidth === true) {
        classNames.push(classes.rootSubmitButtonFullWidth);
    }
    if (form.styling?.submitButtonIncentiveStyle === true) {
        classNames.push(classes.rootSubmitButtonIncentiveStyle);
    }
    if (form.styling?.inputIncentiveStyle === true) {
        classNames.push(classes.rootInputIncentiveStyle);
    }

    return (
        <div className={classNames.join(' ')}>
            <FormInternal form={form} initialData={initialData} logLevel={logLevel} />
        </div>
    )

}